<script>
import {messageMethods, profileComputed, profileMethods} from "@/state/helpers";
import {required} from "vuelidate/lib/validators";

export default {
	name: "chatIfNoUsers",
	data() {
		return {
			showDangerText: false,
			errorAge: false,
			showModal: false,
			submitted: false,
			modalStep: 1,
			selectedGender: 0,
			personalGender: 1,
			genderOptions: [
				{value: 0, name: 'Girlfriend'},
				{value: 1, name: 'Boyfriend'},
			],
			form: {
				first_name: "",
				age: "",
				city: "",
				nationality: "",
				hobbies: "",
				study: "",
				occupation: "",
				personality: "",
				dream: "",
			},
			genderForm: {
				genderName: "",
				genderAge: "",
				genderCity: "",
				genderNationality: "",
				genderStudy: "",
				genderOccupation: "",
				genderPersonality: "",
				genderHobby: "",
				dreamsAndGoals: "",
			}
		}
	},
	validations: {
		form: {
			first_name: {required},
		},
		genderForm: {

		}
	},
	computed: {
		...profileComputed
	},
	methods: {
		...messageMethods,
		...profileMethods,
		openCreateChatModal() {
			this.showModal = true;
			if(this.userInfo.first_name){
				this.form.name = this.userInfo.first_name;
				this.form.first_name = this.userInfo.first_name;
				this.form.age = this.userInfo.age;
				this.form.city = this.userInfo.city;
				this.form.hobby = this.userInfo.hobby;
				this.form.nationality = this.userInfo.nationality;
				this.form.study = this.userInfo.study;
				this.form.occupation = this.userInfo.occupation;
				this.form.personality = this.userInfo.personality;
				this.form.dream = this.userInfo.dream;
			}
		},
		goToNextStep() {
			this.submitted = true;
			if (this.$v.form.$invalid) {
				return false;
			} else {
				if(this.form.age === null) {
					this.updateUserInfo(this.form)
					this.$v.$reset();
					this.modalStep = 2;
				} else if(this.form.age === ''){
					this.updateUserInfo(this.form)
					this.$v.$reset();
					this.modalStep = 2;
				} else if(this.form.age !== '' && this.form.age < 14) {
					this.errorAge = true
				} else if(this.form.age !== '' && this.form.age > 100) {
					this.errorAge = true
				} else {
					this.updateUserInfo(this.form)
					this.$v.$reset();
					this.modalStep = 2;
				}
			}
		},
		transformData() {
			return {
				gender: this.selectedGender,
				name: this.genderForm.genderName,
				age: this.genderForm.genderAge,
				city: this.genderForm.genderCity,
				hobby: this.genderForm.genderHobby,
				nationality: this.genderForm.genderNationality,
				study: this.genderForm.genderStudy,
				personality: this.genderForm.genderPersonality,
				occupation: this.genderForm.genderOccupation,
				dream: this.genderForm.dreamsAndGoals,
				personal_gender: this.personalGender,
				personal_name: this.form.first_name,
				personal_age: this.form.age,
				personal_city: this.form.city,
				personal_hobby: this.form.hobby,
				personal_nationality: this.form.nationality,
				personal_study: this.form.study,
				personal_personality: this.form.personality,
				personal_occupation: this.form.occupation,
				personal_dream: this.form.dream,
			}
		},
		createChatRoom() {
			this.submitted = true;
			// stop here if form is invalid
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			} else {
				this.$store.dispatch('messageState/createChatRoom', this.transformData())
			}
		},
	}
}
</script>

<template>
	<div class="user-chat-empty w-100 overflow-hidden">
		<div class="d-lg-flex">
			<div class="m-auto col-md-8 col-lg-6 col-xl-6">
				<div class="card" style="margin-top: 120px;">
					<div class="card-body p-4">
						<div class="text-center px-4">
							<h1>There are no chats yet...</h1>
						</div>
						<div class="text-center pt-4">
							<p>Click button below to create your first chat</p>
							<button class="btn btn-primary" @click="openCreateChatModal">Create chat</button>
						</div>
					</div>
				</div>
				<b-modal
					v-model="showModal"
					title="Create chat"
					hide-footer
				>
					<form @submit.prevent="createChatRoom">
						<div v-if="modalStep === 1">
							<h3>Personal Info</h3>
							<div class="form-group mb-2">
								<b-form-select
									v-model="personalGender"
									:options="genderOptions"
									class="mb-3"
									value-field="value"
									text-field="name"
									disabled-field="notEnabled"
								></b-form-select>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="addcontactName-input">{{
												$t("chat.tabs.chats.modal.form.name.label")
											}}</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											:placeholder="$t('chat.tabs.chats.modal.form.name.placeholder')"
											v-model="form.first_name"
											:class="{ 'is-invalid': submitted && $v.form.first_name.$error }"
										/>
										<div
											v-if="submitted && $v.form.first_name.$error"
											class="invalid-feedback"
										>
											<span v-if="!$v.form.first_name.required">{{$t("chat.tabs.chats.modal.form.name.validation.required")}}</span>
										</div>
									</b-col>
									<b-col>
										<label for="updateName-input">Age</label>
										<input
											type="number"
											class="form-control"
											id="updateName-input"
											placeholder="Age"
											@input="errorAge = false"
											v-model="form.age"
										/>
										<span v-if="errorAge" class="text-danger">Age must be greater than 14 and lower than 100</span>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="updateName-input">City</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="City"
											v-model="form.city"
										/>
									</b-col>
									<b-col>
										<label for="updateName-input">Nationality</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Nationality"
											v-model="form.nationality"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="updateName-input">Study</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Study"
											v-model="form.study"
										/>
									</b-col>
									<b-col>
										<label for="updateName-input">Personality</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Personality"
											v-model="form.personality"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="updateName-input">Occupation</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Occupation"
											v-model="form.occupation"
										/>
									</b-col>
									<b-col>
										<label for="updateName-input">Hobby/Interest</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Hobby/Interest"
											v-model="form.hobby"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<label for="updateName-input">Dream&Goals</label>
								<input
									type="text"
									class="form-control"
									id="updateName-input"
									placeholder="Dream&Goals"
									v-model="form.dream"
								/>
							</div>
							<div class="text-right pt-5 mt-3">
								<b-button variant="link" @click="showModal = false">{{
										$t("chat.tabs.chats.modal.form.buttons.close")
									}}
								</b-button>
								<b-button type="submit" variant="primary" class="ml-1" @click.prevent="goToNextStep">Next
								</b-button>
							</div>
						</div>
						<div v-if="modalStep === 2">
							<h3>Partner Info</h3>
							<div class="form-group mb-2">
								<b-form-select
									v-model="selectedGender"
									:options="genderOptions"
									class="mb-3"
									value-field="value"
									text-field="name"
									disabled-field="notEnabled"
								></b-form-select>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="addcontactName-input">Name</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											:placeholder="$t('chat.tabs.chats.modal.form.name.placeholder')"
											v-model="genderForm.genderName"
											minlength="3"
											required
										/>
									</b-col>
									<b-col>
										<label for="addcontactName-input">Age</label>
										<input
											type="number"
											class="form-control"
											id="addcontactName-input"
											placeholder="Age"
											v-model="genderForm.genderAge"
											min="15"
											max="100"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="addcontactName-input">City</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											placeholder="City"
											v-model="genderForm.genderCity"
										/>
									</b-col>
									<b-col>
										<label for="addcontactName-input">Nationality</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											placeholder="Nationality"
											v-model="genderForm.genderNationality"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="addcontactName-input">Study</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											placeholder="Study"
											v-model="genderForm.genderStudy"
										/>
									</b-col>
									<b-col>
										<label for="addcontactName-input">Occupation</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											placeholder="Occupation"
											v-model="genderForm.genderOccupation"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<b-row>
									<b-col>
										<label for="addcontactName-input">Personality</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											placeholder="Personality"
											v-model="genderForm.genderPersonality"
										/>
									</b-col>
									<b-col>
										<label for="addcontactName-input">Hobby/Interest</label>
										<input
											type="text"
											class="form-control"
											id="addcontactName-input"
											placeholder="Hobby/Interest"
											v-model="genderForm.genderHobby"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-2">
								<label for="addcontactName-input">Dream&Goals</label>
								<input
									type="text"
									class="form-control"
									id="addcontactName-input"
									placeholder="Dream&Goals"
									v-model="genderForm.dreamsAndGoals"
								/>
							</div>
							<div class="text-right pt-5 mt-3">
								<b-button variant="link" @click="modalStep = 1">Back</b-button>
								<b-button type="submit" variant="primary" class="ml-1">{{
										$t("chat.tabs.chats.modal.form.buttons.save")
									}}
								</b-button>
							</div>
						</div>
					</form>
				</b-modal>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>